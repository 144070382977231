import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useLanguage } from "../context/LanguageContext";
import { translate } from "../utils/translation";

function NotFoundPage() {
  const { language } = useLanguage() || { language: "en" }; // Fallback to "en" if language is undefined

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div>
        <h2 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          {translate("404.message", language)}
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
